<template>
  <div class="add">
    <back />
    <div class="form" v-loading="loading">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-form-inline" :disabled="id ? true : false">
        <el-form-item label="消息名称" prop="msgTitle">
          <el-input v-model="ruleForm.msgTitle" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="消息内容" prop="msgContent">
          <el-input class="textarea" v-model="ruleForm.msgContent" type="textarea" placeholder="请输入内容" :rows="6" maxlength="200" show-word-limit resize="none"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="divider">
      <el-divider></el-divider>
    </div>
    <div class="list">
      <div class="head">
        <div class="label">联系人</div>
        <div class="btn">
          <el-button v-if="id ? false : true" type="primary" size="small" @click="handleAdd(1)">添加供应商</el-button>
          <el-button v-if="id ? false : true" type="primary" size="small" @click="handleAdd(2)">添加商超</el-button>
        </div>
      </div>
      <div class="globle_table">
        <el-table :data="ruleForm.peopleList" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- {{ scope.$index + 1 + (currentPage - 1) * pageSize }} -->
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="联系人类型">
            <template slot-scope="scope">
              <span v-if="scope.row.bizType == 1">供应商</span>
              <span v-if="scope.row.bizType == 2">商超</span>
            </template>
          </el-table-column>
          <el-table-column prop="bizName" label="名称"></el-table-column>
          <el-table-column v-if="!id" label="操作" header-align="center" align="center" width="120">
            <template slot-scope="scope">
              <el-button class="btn" v-if="id ? false : true" type="danger" size="" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="submit" v-if="!id">
      <el-button type="primary" @click="onSubmit">发送</el-button>
    </div>

    <el-dialog title="添加供应商" :visible.sync="supplierDialog">
      <div class="supplier">
        <div class="search">
          <el-input v-model="supplierName" placeholder="请输入名称" @change="onSearch1" clearable></el-input>
        </div>
        <div class="globle_table">
          <el-table v-loading="tableLoading" :data="supplierList" style="width: 100%" max-height="540" @selection-change="handleSelectionChange1">
            <el-table-column type="selection" width="100"></el-table-column>
            <el-table-column prop="number" label="序号" width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="bizName" label="名称"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel1">取 消</el-button>
        <el-button type="primary" @click="handleContcatList">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加商超" :visible.sync="shopDialog">
      <div class="shop">
        <div class="search">
          <el-input v-model="shopName" placeholder="请输入名称" @change="onSearch2" clearable></el-input>
        </div>
        <div class="globle_table">
          <el-table v-loading="tableLoading" :data="shopList" style="width: 100%" max-height="540" @selection-change="handleSelectionChange2">
            <el-table-column type="selection" width="100"></el-table-column>
            <el-table-column prop="number" label="序号" width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="bizName" label="名称"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel2">取 消</el-button>
        <el-button type="primary" @click="handleContcatList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
function createdForm() {
  return {
    msgTitle: "",
    msgContent: "",
    peopleList: [],
    supplierList: [],
    shopList: [],
  }
}

export default {
  data() {
    return {
      loading: false,
      id: this.$route.query.id,
      ruleForm: createdForm(),
      rules: {
        msgTitle: [{ required: true, message: "请输入内容", trigger: "blur" }],
        msgContent: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      tableData: [],
      tableLoading: false,
      shopName: "",
      supplierName: "",
      dialogTitle: "添加",
      supplierDialog: false,
      shopDialog: false,
      supplierList: [],
      shopList: [],
      existingBizIds: new Set(),

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    if (this.id) {
      this.getDetail()
    } else {
      this.getLists1()
      this.getLists2()
    }
  },
  methods: {
    getDetail() {
      this.loading = true
      this.$axios
        .get(this.$api.selectPlatformMsgDetail, {
          params: {
            id: this.id,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.ruleForm = res.data.result
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    handleAdd(num) {
      if (num == 1) {
        this.supplierDialog = true
      } else {
        this.shopDialog = true
      }
    },
    getLists1() {
      this.$axios
        .get(this.$api.selectSupplierInfoList, {
          params: {
            supplierName: this.supplierName,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.supplierList = res.data.result.map(item => {
              return {
                bizId: item.supplierId,
                bizName: item.supplierName,
                bizType: 1,
              }
            })
          } else {
            this.$message.error("供应商列表获取失败")
          }
        })
    },
    getLists2() {
      this.$axios
        .get(this.$api.selectShopInfoList, {
          params: {
            shopName: this.shopName,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.shopList = res.data.result.map(item => {
              return {
                bizId: item.shopId,
                bizName: item.shopName,
                bizType: 2,
              }
            })
          } else {
            this.$message.error("商超列表获取失败")
          }
        })
    },
    handleDel(row) {
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.ruleForm.peopleList.splice(this.ruleForm.peopleList.indexOf(row.bizId), 1)
      })
    },
    onSearch1() {
      this.getLists1()
    },
    onSearch2() {
      this.getLists2()
    },
    handleSelectionChange1(array) {
      this.ruleForm.supplierList = array
    },
    handleSelectionChange2(array) {
      this.ruleForm.shopList = array
    },
    onCancel1() {
      this.ruleForm.supplierList = []
      this.supplierDialog = false
    },
    onCancel2() {
      this.ruleForm.shopList = []
      this.shopDialog = false
    },
    handleContcatList() {
      this.ruleForm.peopleList = [...this.ruleForm.supplierList, ...this.ruleForm.shopList]
      this.supplierDialog = false
      this.shopDialog = false
    },
    onSubmit() {
      let params = Object.assign({}, this.ruleForm)
      let arr = params.peopleList.map(e => e.bizType)
      if (arr.includes(1) && arr.includes(2)) {
        params.receiveRole = 3
      } else if (arr.includes(1)) {
        params.receiveRole = 1
      } else if (arr.includes(2)) {
        params.receiveRole = 2
      }
      if (!params.msgTitle || !params.msgContent) {
        this.$message.error("请填写完整信息")
      } else if (params.peopleList.length == 0) {
        this.$message.error("请选择至少一个供应商或商超")
      } else {
        this.$delete(params, "supplierList")
        this.$delete(params, "shopList")
        this.$axios.post(this.$api.insertPlatformMsg, params).then(res => {
          if (res.data.code == 100) {
            this.$message.success("群发成功")
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.add {
  .form {
    margin-top: 20px;
    padding-right: 40px;

    ::v-deep .el-form-item__label {
      color: #101010;
      font-size: 16px;
      padding-right: 30px;
    }

    .textarea {
      ::v-deep .el-textarea__inner {
        background: #f2f2f2;
        border-radius: 10px;
        border: none;
      }
      ::v-deep .el-input__count {
        background: #f2f2f2;
      }
    }

    .is-disabled ::v-deep .el-textarea__inner {
      background: #f5f7fa;
    }
  }

  .divider {
    padding: 0 30px;
  }

  .list {
    padding: 0 30px;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .label {
        color: #101010;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .search {
    margin-bottom: 20px;
  }
  .submit {
    text-align: center;
    margin-top: 50px;
    .el-button {
      width: 150px;
    }
  }
}
</style>
